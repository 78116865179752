<template>
  <v-alert
    shaped
    outlined
    dismissible
    transition="scale-transition"
    :type="alertType"
    :value="alert"
  >
    {{ alertText }}
  </v-alert>
</template>

<script>
export default {
  name: "Alert",
  data() {
    return {
      alert: false,
    };
  },
  props: {
    alertText: {
      type: String,
      required: true,
    },
    alertType: {
      type: String,
      required: true,
    },
    alertShow: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    alert(newVal) {
      if (newVal == true) {
        setTimeout(() => {
          this.alert = false;
          this.$emit("closeAlert");
        }, 3000);
      }
    },
    alertShow(newVal) {
      if (newVal == true) {
        this.alert = true;
      }
    },
  },
};
</script>